const AppRoutes = {
  index: "/",
  auth: "/auth",
  login: "login",
  dashboard: "/dashboard",
  newTask:"create-task",
  newCombineTask:"create-video-merge-task",
  channels:"channels",
  channel:"channel",
  tasks: "tasks",
  users: "users",
  user: "user",
  payments: "payments",
  payment: "clear",
  languages: "languages",
  language: "language",
  appSettings: "app-settings",
  music: "music",
  backgroundMusics: "background-music",
  localization: "localization",
  packages: "packages",
  trials: "trials",
  package: "package",
  contents: "contents",
  content: "content",
  types: "types",
  type: "content-type",
  sections: "sections",
  section: "content-section",
  buckets:"sources",
  bucket: "source",
  password:"reset-password",
  featured:"featured-content",
  cicd:"ci-cd",
  plays:"play-now"
};

export default AppRoutes;