import AppRoutes from "../routing/routes";
import SessionManager from "clientside-session-manager";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppStore from "./@redux/store";
import { v4 as uuidv4 } from "uuid";
import { showSnackBar } from "./@redux/snackbar";
import { setVideos } from "./@redux/videos";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from 'moment-timezone';

export const isProduction = () => `${process.env.REACT_APP_ENV}` === "production";

export const isStaging = () => `${process.env.REACT_APP_ENV}` === "staging";

export const isDev = () => `${process.env.REACT_APP_ENV}` === "development";

export const paginationOption = [10, 25, 50, 100, 200, 500];

const authTag = "authenticated",
  sessionTimeout = isProduction() || isStaging() ? 30 : 86400,
  tagKey = "apiKey",
  tempUid = "_uid";
  
export const baseUrl = () => {
  return isProduction()
    ? "https://upload.ubongo.org/v3/"
    : isStaging() 
    ? "https://indev.ubongo.org/v3/"
    :"http://localhost:1100/v3/";
};

export const refreshTimeout = 4000; //milliseconds

export const paymentTimeout = 75; //seconds

export const filesUrl = "";

export const acceptedMimes = "image/jpg,image/png";

export const maxFileSize = 2097152;

export const InitSessionObserver = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.location.pathname.includes(AppRoutes.dashboard)) {
      SessionManager.updateToken(`token-${new Date().getTime()}`);
      var timeoutId = parseInt(sessionStorage.getItem("timeOut") || "-1");
      clearTimeout(parseInt(timeoutId));
      timeoutId = setTimeout(() => logout(), sessionTimeout * 1000 * 60);
      sessionStorage.setItem("timeOut", String(timeoutId));
    }
  }, [location]);
};

export const generateUniqueId = () => uuidv4();

export const initApp = () => {
  if (!localStorage.getItem(tempUid)) {
    localStorage.setItem(tempUid, generateUniqueId());
  }
};

export const getHandlerPathProps = (payload) => {
  const parts = payload.path.split("/");
  return {
    path: parts[0],
    event: parts[parts.length - 1].split("?")[0],
    returnPath: `${payload.nodeId}/${payload.path}`,
  };
};

export const watchLiveData = (event, callback) => {
  const socket = AppStore.getState().socket.instance;
  const eventCallback = (data) => callback(data);
  socket.onAny((eventName, ...args) => {});
  socket.off(`${event}`, eventCallback);
  socket.on(`${event}`, eventCallback);
};

export const liveData = (event, filterMatches, callback) => {
  watchLiveData(event, (data) =>
    filterMatches(data) ? callback(data) : () => { }
  );
};

export const listLiveUpdate = (
  list,
  filterMatches,
  callback,
  event = "log"
) => {
  watchLiveData(event, (data) => {
    if (filterMatches(data)) {
      const _list = [].concat(list);
      const activeIndex = _list.findIndex((item) => item._id === data._id);
      if (activeIndex < 0) {
        _list.push(data);
      } else {
        _list[activeIndex] = data;
      }
      callback(_list);
    }
  });
};

export const subscribe = (
  path,
  content = null,
  callback = null,
  onLoading = () => { },
  headers = {}
) => {
  onLoading && onLoading(true);
  const payload = {
    path: path,
    data: content,
    headers: cleanObject({
      "source-app": "web",
      "x-api-key": SessionManager.getDetail(tagKey),
      ...headers,
    }),
    nodeId: localStorage.getItem(tempUid),
  };
  const socket = AppStore.getState().socket.instance;
  return new Promise((resolve) => {
    socket.timeout(30000).emit("aws-youtube-ubongo", payload, (error, data) => {
      onLoading && onLoading(false);
      callback &&
        callback(
          error || data.error ? data || error : null,
          error || data.error ? null : data
        );
      resolve(error || data.error ? null : data);
    });
  });
};

export const navigate = (path, dashboard = false) => {
  AppStore.getState().navigator.navigate(
    `${dashboard ? AppRoutes.dashboard : AppRoutes.auth}/${path}`
  );
};

export const SetVideos = (videos) => {
  AppStore.dispatch(setVideos(videos));
};

export const getVideos = () => {
  return AppStore.getState().videos.instance;
};

export const loggedInitSession = (response) => {
  const data = jwt_decode(response.data).data;
  data.role = parseInt(`${data.role}`);
  if (data.role === 1 || data.role === 2) {
    SessionManager.create(
      `token-${new Date().getTime()}`,
      { ...data, authenticated: true, "auth-token": response.data },
      sessionTimeout
    );
    updateAddSessionValue(authTag, true);
    navigate(AppRoutes.contents, true);
  } else {
    logout();
  }
};

export const getParams = () => {
  if (window.location.search) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const objs = Object.fromEntries(urlSearchParams.entries());
    var _objs = {};
    for (const key in objs) {
      _objs[`${key}`] =
        objs[key] === "true" || objs[key] === "false"
          ? JSON.parse(objs[key])
          : objs[key];
    }
    return _objs;
  } else {
    return {};
  }
};
export const guardRoute = (recover = false, allowed = true) => {
  if (recover && recover === true) {
    if (!getParams().code) navigate(AppRoutes.login);
  } else {
    const valid = SessionManager.exists(),
      path = window.location.pathname,
      deepLink = path.includes(".well-known"),
      navToLogin =
        !valid &&
        ((!path.includes(AppRoutes.login) &&
          !path.includes(AppRoutes.forgot) &&
          !path.includes(AppRoutes.recover)) ||
          !allowed);
    if (navToLogin && !deepLink) {
      window.location.href = `${AppRoutes.auth}/${AppRoutes.login}`;
      return;
    }

    const editorView = path.includes(AppRoutes.users) && valid && getActiveUser().role === UserRoles.editor;

    if ((valid && !deepLink) || !allowed) {
      const data = SessionManager.getAll(),
        navToSummary =
          (data.authenticated && !path.includes(AppRoutes.dashboard)) ||
          !allowed || editorView;

      if (navToSummary) navigate(AppRoutes.contents, true);
    }
  }
};

export const updateAddSessionValue = (key, value, update = false) => {
  if (update) {
    SessionManager.updateDetail(key, value);
  } else {
    SessionManager.addDetail(key, value);
  }
};

export const logout = () => {
  SessionManager.destroy();
  localStorage.removeItem(tempUid);
  initApp();
  window.location.reload();
};

export const getActiveUser = () => {
  return SessionManager.getAll();
};

export const getUserRole = (role) => {
  return role === 1 ? "Admin" : role === 2 ? "Editor":"Guest";
};

export const UserRoles = {
  system: 0,
  admin: 1,
  editor: 2,
};

export const getUserStatus = (status) => {
  return {
    isLabel: true,
    value: status === true
        ? "ACTIVE"
        : "INACTIVE",
    color: status === true
        ? "success"
        : "error",
  };
};

export const purpose = (status) => {
  return {
    isLabel: true,
    value: status === true
        ? "YES"
        : "NO",
    color: status === true
        ? "success"
        : "error",
  };
};

export const scheduled = () => {
  return {
    isLabel: true,
    value: "SCHEDULED",
    color: "info",
  };
};

export const getAvatarLabel = (objects, mKey) => {
  var keys = Object.keys(objects),
    label = null;
  for (let step = 0; step < keys.length; step++) {
    if (keys[step].includes(mKey) && objects[keys[step]]) {
      label = objects[keys[step]];
    }
  }
  if (label) {
    return `${label.split(" ")[0]}`.substring(0, 1).toUpperCase();
  }
  return ".";
};

export const validateEmail = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const showSnack = (message) => {
  AppStore.dispatch(showSnackBar({ message: message }));
};

export const isError = (field) => {
  return field.error && field.touched;
};

export const cleanObject = (data) => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "string" && data[key].trim().length <= 0) {
      delete data[key];
    }
  });
  return data;
};

export const validateFields = (fields) => {
  var validated = [];
  Object.keys(fields).forEach((key) => {
    validated.push(!fields[key].error && fields[key].touched);
  });
  return validated.filter((entry) => !entry).length === 0;
};

export const getStatus = (status, plain = false) => {
  const success = status === "COMPLETE" || status === "Authenticated" || status === "Active";
  const pending = status === "PENDING" || status === "PROCESSING" || `${status}`.startsWith("STREAMING") || `${status}`.startsWith("YOUTUBE");
  const failed = status === "ERROR" || status === "Needs Auth" || "Expired";
  const info = status === "FINALIZING" || `${status}`.startsWith("VIDEO") || (!success && !pending && !failed);
  
  const color = info
    ? "info"
    : success
      ? "success"
      : pending
        ? "warning"
        : failed
          ? "error"
          : "info";
  return {
    isLabel: true,
    value: `${status}`.toUpperCase(),
    color: color,
    icon: success
      ? plain
        ? "check"
        : "check_circle"
      : pending
        ? plain
          ? "query_builder"
          : "query_builder"
        : failed
          ? plain
            ? "close"
            : "highlight_off"
          : "error_outline",
  };
};


export const getHeaders = () => {
  const token = SessionManager.getDetail("auth-token")
  if (!token) return null;
  return {
    headers: { Authorization: `Bearer ${token}` }
  };
}
export const sendGetRequest = async (endPoint, onLoading, onResponse) => {
  if(onLoading) onLoading(true);
  return new Promise((resolve) => {
    axios
      .get(`${baseUrl()}${endPoint}`, getHeaders())
      .then((response) => {
        if (onLoading) onLoading(false);
        if (onResponse) onResponse(response.data.data);
        resolve(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        if (onLoading) onLoading(false);
        if (onResponse) onResponse(null);
        resolve(null);
      });
  })
};

export const getStreamingReport = async (onLoading, onResponse) => {
  if (onLoading) onLoading(true);
  return new Promise((resolve) => {
    axios
      .get(`${process.env.REACT_APP_STREAM_GENERATOR_URL}/tasks/report?env=${isProduction() ? "production":"development"}`, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_STREAM_GENERATOR_TOKEN}` }
      }).then((response) => {
        if (onLoading) onLoading(false);
        if (onResponse) onResponse(response.data.data);
        resolve(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        if (onLoading) onLoading(false);
        if (onResponse) onResponse(null);
        resolve(null);
      });
  })
};

export const sendPostRequest = (endPoint, data, onLoading, onResponse) => {
  if(onLoading) onLoading(true);
  let configs = getHeaders()
  if(data.files){
    configs.headers = {...configs.headers, 'Content-Type': 'multipart/form-data'}
  }
  return new Promise((resolve) => {
    axios
    .post(`${baseUrl()}${endPoint}`, data, configs)
    .then((response) => {
      if(onLoading) onLoading(false);
      if(onResponse) onResponse(response.data)
      resolve(response.data)
    })
    .catch((error) => {
      console.error(error)
      if(onLoading) onLoading(false);
      resolve(null)
      if(onResponse) onResponse(error.response.data)
    });
  })
};


export const sendPatchRequest = (endPoint, data, onLoading, onResponse) => {
  if(onLoading) onLoading(true);
  let configs = getHeaders()
  if(data.files){
    configs.headers = {...configs.headers, 'Content-Type': 'multipart/form-data'}
  }
  return new Promise((resolve) => {
    axios
    .patch(`${baseUrl()}${endPoint}`, data, configs)
    .then((response) => {
      if(onLoading) onLoading(false);
      if(onResponse) onResponse(response.data)
      resolve(response.data)
    })
    .catch((error) => {
      if(onLoading) onLoading(false);
      resolve(null)
      if(onResponse) onResponse(error.response.data)
    });
  })
};

export const sendDeleteRequest = async (endPoint, uuid, onLoading, onResponse) => {
  if(onLoading) onLoading(true);
  return new Promise((resolve) => {
    axios
    .delete(`${baseUrl()}${endPoint}/${uuid}`, getHeaders())
    .then((response) => {
      if(onLoading) onLoading(false);
      if(onResponse) onResponse(response.data)
      resolve(response.data)
    })
    .catch((error) => {
      if(onLoading) onLoading(false);
      resolve(null)
      if(onResponse) onResponse(error.response.data)
    });
  })
};

export const formatDate = (dateTime, format = "MMM DD, YYYY") => {
  return moment(dateTime).format(format);
}

export const toTzTime = (dateTime) => {
  return moment(dateTime).tz("Africa/Dar_es_Salaam");
}

export const getFolderName = (folder, capitalize = true) => {
  const parts = folder.split("/");
  if(parts.length === 1) return folder;
  const name = parts[parts.length - 2];
  return capitalize ? name.charAt(0).toUpperCase() + name.slice(1) : name;
}

export const getDisplayFolderName = (name) => {
  const newName =  name.replace("_", " ")
  .replace("%21","!")
  .replace("%27","'")
  .replace("%28","(")
  .replace("%29",")")
  .replace("%2C",",")
  .replace("%20", " ")
  .replace("%2F", " ").substring(0,20)
  return `${name.length <= 20 ? name : newName}${name.length <= 20 ? "":"..."}`
}

export const removeEmpty = (_object) => {
  const clean = (obj) => {
    if (Array.isArray(obj)) {
      return (obj
        .map(v => (v && typeof v === 'object') && Object.keys(v).length ? clean(v) : v)
        .filter(v => !(v == null))).filter(_obj => Object.keys(_obj).length > 0);
    } else {
      const _obj = Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' && Object.keys(v).length ? clean(v) : v])
        .reduce((a, [k, v]) => (v === null || v === "null" || v === undefined ? a : (a[k] = v, a)), {});

      return Object.keys(_obj).length ? _obj : null;
    }
  }
  return clean(_object);

}

export const shortenUrl = (url) => {
  const parts = url.split('/');
  const shortened = `${parts[0]}//${parts[2]}/.../${parts.slice(parts.length - 1).join('/')}`;
  return shortened;
}
